/* Container for browser page */
.browser-page {
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin-bottom:40px;

  color: #000000;
}

/* The headline of a blog listing */
.blog-listing-headline {
  font-size: 2.0rem;
}

/* The summary of a blog listing */
.blog-listing-summary {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* The author field of a blog listing */
.blog-listing-author {
  font-size: 0.8rem;
}

/* The date field of a blog listing */
.blog-listing-date {
  font-size: 0.8rem;
}

/* The continue link on a blog listing */
.blog-listing-go-to-article {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #1E88E5;
  margin-top: 10px;
}




.blog-page {
  margin-bottom:40px;

  color: #000000;
}
@media only screen and (min-width: 481px) {
  .blog-page {
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    margin-bottom:40px;

    color: #000000;
  }
}

/* The headline of a blog page */
.blog-page-headline {
  font-size: 3.0rem;
  margin-bottom: 20px;
}

/* The summary of a blog page */
.blog-page-summary {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* The author field of a blog page */
.blog-page-author {
  font-size: 1.0rem;
}

/* The date field of a blog page */
.blog-page-date {
  font-size: 1.0rem;
}

/* A link on a blog page */
.blog-page-link {
  font-size: 1.0rem;
  font-weight: normal;
  color: #1E88E5;
}

/* The article content on a blog page */
.blog-page-article p {
  font-size: 1.2rem;
}

h1.blog-page-article {
  font-size: 3.2rem;
}

.blog-page-article h2 {
  font-size: 2.2rem;
}

.blog-page-article h3 {
  font-size: 1.6rem;
}
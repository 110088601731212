/* Material Design Icons */
@import '~material-design-icons/iconfont/material-icons.css';

/* Materialize CSS */
@import '~materialize-css/dist/css/materialize.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #FAFAFA;
}

@font-face {
  /* Prevent text of material icons showing when loading the page */
  font-family: 'Material Icons';
  font-display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Responsive images */
img.responsive {
  max-width: 100%;
  height: auto;
}

/* Navbar logo */
.nav-wrapper .brand-logo img {
    height: 2rem; /* h1 in brand logo is 2.1rem, but the .1 leads to strange cut-offs */
    margin-right: 10px;
}
/* Navbar headline */
.nav-wrapper span {
  color: black;
  font-size: 2rem;
  position: absolute;
  top: -3px;
}
/* Navbar items */
.nav-wrapper ul li a {
  color: black;
  font-size: 1.2rem;
}
/* Sidenav menu icon */
a.sidenav-trigger i.material-icons {
  color: black;
  font-size: 2.5rem;
}
/* Sidenav menu header */
ul.sidenav li.sidenav-heading {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
  padding-right: 32px;
  padding-left: 32px;
}
/* Sidenav menu items */
ul.sidenav li a {
  color: black;
  font-size: 1.5rem;
  margin-top: 10px;
}


/* Usercentrics button */
div #uc-privacy-button {
  position: absolute;
  transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5); /* IE 9 */
  -webkit-transform: scale(0.5, 0.5); /* Safari and Chrome */
  -o-transform: scale(0.5, 0.5); /* Opera */
  -moz-transform: scale(0.5, 0.5); /* Firefox */
}


/* Materializecss: non-caps buttons */
.no-uppercase {
  text-transform: none;
}


/* Insights Form */
div .insights-container {
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 481px) {
  div .insights-container {
    /* show shadow only on larger screens */
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

/* Badge on top of insights to promote it */
div.insights-badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.insights-badge {
  text-align: center;
  padding: 5px 10px;
  font-size: 1.2rem;
  margin-top: 10px;
  box-shadow: 0 0px 1px 0 rgba(255, 0, 0, 1.0), 0 0px 1px 0 rgba(255, 0, 0, 1.0);
  font-weight: bold;
}
/*
div.insights-badge-container {
  position: relative;
  display: inline-block;
  margin-bottom:20px;
}
div.insights-badge {
    background:red;
    text-align: center;
    color:white;
    padding: 10px 10px;
    font-size:1.2rem;
}
*/



/* General box for forms and so on */
div .immovestics-container {
  padding: 40px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.2);
}


/* General transformations: rotation */
.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate135 {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate25 {
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}
.rotate15 {
  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}
.rotate10 {
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);

}


/* Insights: general option to remove arrows from number input fields */
input.no-arrows-input::-webkit-outer-spin-button { /* no arrows */
  -webkit-appearance: none;
  margin: 0;
}
input.no-arrows-input::-webkit-inner-spin-button { /* no arrows */
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.no-arrows-input[type=number] { /* no arrows */
  -moz-appearance: textfield;
}

/**********************************/
/* Input fields (Materialize.css) */
/**********************************/

/* label color */
input label {
  color: #000;
}
/* label focus color */
input:focus + label {
  color: #000 !important;
}
/* label underline focus color */
input:focus {
  border-bottom: 1px solid #1E88E5 !important;
  box-shadow: 0 1px 0 0 #1E88E5 !important;
}
/* valid color */
input.valid {
  border-bottom: 1px solid #1E88E5 !important;
  box-shadow: 0 1px 0 0 #1E88E5 !important;
}
/* invalid color */
input.invalid {
  border-bottom: 1px solid #1E88E5 !important;
  box-shadow: 0 1px 0 0 #1E88E5 !important;
}
/* icon prefix focus color */
.input-field .prefix.active {
  color: #1E88E5 !important;
}


/* Insights main form middle content */
.insights-eval-form-content {
  min-height: 275px;
  display: flex;
  /*
  min-height: 150px;
  */
  /*align-items: center;*/
  justify-content: center;
  height: 100%;

  flex-direction: column;
  /*
  */
}


/**********************/
input[type="radio"]:checked+span:after {
    background-color: #1E88E5 !important;
}
input[type="radio"]:checked+span:after, input[type="radio"].with-gap:checked+span:after {
    border: 2px solid #1E88E5 !important;
}


/* Insights slider options */
.insights-slider {
}
.insights-slider .noUi-handle { /* make slider smaller to not touch borders */
}
.insights-slider .noUi-handle:before, .insights-slider .noUi-handle:after { /* hide vertical lines on slider handle */
  width: 0px !important;
}
.insights-slider .noUi-tooltip {
  display: none;
}
.insights-slider .noUi-active .noUi-tooltip {
  display: block;
}
.insights-slider .noUi-active {
  outline: none;
}
.insights-slider .noUi-focus {
  outline: none;
}
.insights-slider .noUi-connect {
  background: #1E88E5;
}


/* Insights form: button to select the object type */
.objectTypeButton {
  height: 150px;
  width: 150px;
}


/****************************/
/* Insights analysis footer */
/****************************/

.insights-analysis-main-row {
  margin-top: 10px;
}
.insights-analysis-sub-row {
  text-align: left;
  font-size: 1.2rem;
}
.insights-analysis-main-category {
  font-size: 1.2rem;
  text-align: left;
}
.insights-analysis-sub-category {
  text-align: left;
}
.insights-analysis-main-value {
  font-size: 1.2rem;
  text-align: right;
}
.insights-analysis-sub-value {
  text-align: right;
}

/*************************/
/* Insights summary rows */
/*************************/
.insights-summary-row {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}
.insights-summary-col-main {
  font-weight: normal;
  text-align: left;
}
.insights-summary-col-sub {
  font-weight: normal;
  text-align: left;
}
.insights-summary-col-impact {
  font-weight: normal;
  text-align: left;
}


table.insights-summary-table {
  table-layout:fixed;
}
table.insights-summary-table td {
  padding-right: 20px;
  overflow: hidden;
}
table.insights-summary-table .insights-summary-table-category {
  font-weight: bold;
  width: 50%;
  text-align: right;
}
table.insights-summary-table .insights-summary-table-value {
  width: 35%;
  text-align: left;
}
table.insights-summary-table .insights-summary-table-impact {
  width: 15%;
  text-align: left;
}



.insights-card-row {
  margin-bottom: 0px;
}
.insights-card-text {
  font-size: 1.2rem;
  /*
  position: absolute;                 
  bottom: 0;   
  left: 0;
  right: 0;
  margin: auto;
  padding-bottom: 10px;
  */
}
.insights-card-title-text {
  font-size: 1.4rem !important;
  font-weight: bold !important;
}
.insights-card-input-element {
  display: none;
}
.insights-card-input {
  margin-top: 5px;
  margin-bottom: 5px;
}
.insights-card-input:hover {
    cursor: pointer;
}
.insights-card-input-element:checked + .insights-card-input {
  box-shadow: 0 0 3px 3px #1E88E5;
  color: #1E88E5;
 }
 .insights-card-content {
   padding: 6px !important;
 }



.insights-input-field-select ul li span {
   color: #1E88E5 !important;
 }

 .dropdown-content {
   transform: none !important; /* This fixes a bug with materialise.css and the dropdown on iOS where selection did not work correctly. */
 }

.insights-binary-feature-button {
  min-width:50px;
  font-weight: bold;
  padding-left:10px;
  padding-right:10px;
  margin-left: 2px;
  margin-right: 2px;
  height:100% !important;
}

.insights-binary-feature-button-selected {
  color: #1E88E5 !important;
  box-shadow: 0 0 3px 3px #1E88E5;
  height:100% !important;
}
.insights-binary-feature-button-selected:hover {
  color: #1E88E5 !important;
  box-shadow: 0 0 3px 3px #1E88E5;
  height:100% !important;
}